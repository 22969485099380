// api.js -- I interact with the API
import axios from "axios";
import { ADMIN, USER, APPUSER } from "./Util/constants";

const BASE_URL = "https://rzkiqpzlxxtfqjl.form.io";

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const handleApiError = (error, functionName) => {
  throw new Error(`Error in ${functionName} API: ${error.message}`);
};

export const loginUser = async (formData, setLoading) => {
  try {
    setLoading(true);
    const url = "/pestelitelogin/submission";

    //try {
    const response = await api.post(url, formData);
    const jwtToken = response.headers["x-jwt-token"];
    localStorage.setItem("jwtToken", jwtToken);

    if (response.data.data.role === "admin") {
      localStorage.setItem("role", ADMIN);
    } else {
      localStorage.setItem("role", USER);
      localStorage.setItem("email", response.data.data.email);
    }
    console.log("user", response.data);
    return response.data;
  } catch (error) {
    handleApiError(error, "loginUser");
  } finally {
    setLoading(false);
  }
};

export const processDownloadRequest = async (
  formId,
  submissionId,
  setLoading
) => {
  try {
    setLoading(true);
    const url = "/admin/login";
    const payload = {
      data: {
        email: APPUSER,
        password: "pestelite", // APPUSER.substring(0, 9),
      },
    };

    try {
      const response = await api.post(url, payload);
      const jwtToken = response.headers["x-jwt-token"];

      //get temp downloadtoken
      const tempTokenUrlResponse = await axios.get(BASE_URL + "/token", {
        headers: {
          "x-jwt-token": jwtToken,
          "x-expires": "3600",
        },
      });

      const downloadToken = tempTokenUrlResponse.data.key;

      console.log("downloadToken", downloadToken);

      //download pdf with downloadtoken
      const downloadUrl = `https://api.form.io/project/65778584972fd0f18a111f4e/form/${formId}/submission/${submissionId}/download?token=${downloadToken}`;
      // console.log(downloadUrl);
      // const download = await axios.get(downloadUrl, {
      //   responseType: "blob",
      // });

      //window.open(downloadUrl, "_blank");
      window.location.href = downloadUrl;

      

      return true;
    } catch (error) {
      console.error(`Error in loginUser for ${url}`, error);
    }
  } catch (error) {
    handleApiError(error, "loginUser");
  } finally {
    setLoading(false);
  }
};

export const getSubmissionsList = async (setLoading, clientID=false) => {
  try {
    setLoading(true);

    let url = "/pesiteform/submission?";
    let params = ["limit=500"];
    if(clientID !== false)
    {
      params.push("data.selectClient._id="+clientID);
    }
    
    const response = await api.get(url+params.join("&"));
    return response.data;
  } catch (error) {
    handleApiError(error, "getSubmissionsList");
  } finally {
    setLoading(false);
  }
};

export const getClientList = async (setLoading) => {
  try {
    setLoading(true);
    const response = await api.get("/clientregister/submission?limit=500");
    return response.data;
  } catch (error) {
    handleApiError(error, "getClientList");
  } finally {
    setLoading(false);
  }
};

export const getPesticidesList = async (setLoading) => {
  try {
    setLoading(true);
    const response = await api.get("/pesticidesregister/submission?limit=500");
    return response.data;
  } catch (error) {
    handleApiError(error, "getPesticidesList");
  } finally {
    setLoading(false);
  }
};

export const getUsers = async (setLoading) => {
  try {
    setLoading(true);
    const response = await api.get("/pesteliteusers/submission?limit=500");
    return response.data;
  } catch (error) {
    handleApiError(error, "get users");
  } finally {
    setLoading(false);
  }
};

export const getTasks = async (setLoading) => {
  try {
    setLoading(true);
    const response = await api.get("/pebooking/submission?limit=500");
    return response.data;
  } catch (error) {
    handleApiError(error, "get tasks");
  } finally {
    setLoading(false);
  }
};

export const getUsageLogs = async (setLoading, clientName) => {
  try {
    setLoading(true);
    let url = "/peusagereport/submission?limit=500";
    if (clientName) {
      //{{appUrl}}/register/submission?data.lastName=Smith //From documentation
      url += `?data.client=${clientName}`;
    }
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    handleApiError(error, "getUsageLogs");
  } finally {
    setLoading(false);
  }
};
//Get recommendation for a parent site form
export const getRecommendation = async (setLoading, masterId) => {
  try {
    setLoading(true);
    let url = "/recommendation-technician/submission?limit=500";
    if (masterId) {
      url += `?data.masterFormId=${masterId}`;
    }
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    handleApiError(error, "getUsageLogs");
  } finally {
    setLoading(false);
  }
};

//After saving update the recommendation & action form to have same ID with parent
export const updateMasterFormId = async (formData, setLoading, id, formType) => {
  try {
    setLoading(true);

    console.log('formType', formType);
    let url;
    if (formType === "recommendation") {
      url = `/recommendation-technician/submission/${id}`;
    } else if (formType === "actionreport") {
      url = `/actionreport/submission/${id}`;
    } else {
      url = `/pesiteform/submission/${id}`;
    }
    const response = await api.patch(url, formData);
    return response.data;
  } catch (error) {
    handleApiError(error, "updateMasterFormId");
  } finally {
    setLoading(false);
  }
};

export const checkIfSubmissionExists = async (setLoading, id) => {
  try {
    setLoading(true);
    let url = `/recommendation-technician/exists?data.masterFormId=${id}`;

    const response = await api.get(url);

    return response;

  } catch (error) {
    let createNew = `/recommendation-technician/submission`;
    const data = {
      data: {
        masterFormId: id,
      },
    };

     await api.post(createNew, data);
  } finally {
    setLoading(false);
  }
};

export const getSiteFormId = async (setLoading, id) => {
  try {
    setLoading(true);
    let url = `/pesiteform/exists?data.bookingRef=${id}`;

    const response = await api.get(url);

    return response;
  } catch (error) {
      handleApiError(error, "getSiteFormId");
  } finally {
    setLoading(false);
  }
};

//check acitvity from checklist
export const getActivity = async (setLoading, formId) => {
  
  try {
    setLoading(true);
    const response = await api.get(`/pesiteform/submission/${formId}`);
    const result = response.data;
    console.log("form", result);
    const trapActivity = {
      //will confirm these values for medium and high
      // Filter bait station activity
      baitStations: {
        trapName: "Tamper Proof",
        data: result.data.baitStations.filter(
          (station) => station.rodentActivity === true
        ),
      },

      // Filter mechanical traps activity
      mechanicalTraps: {
        trapName: "Mechanical Trap",
        data: result.data.mechanicalTraps.filter(
          (station) => station.rodentActivity === true
        ),
      },

      // Filter non-toxic traps activity
      noneToxicTraps: {
        trapName: "None Toxic Traps",
        data: result.data.noneToxicTraps.filter(
          (station) => station.rodentActivity === true
        ),
      },

      // Filter cockroaches in general areas with level greater than 4
      generalAreas: {
        trapName: "General Areas",
        data: result.data.generalAreaCockroaches.filter(
          (station) => station.level > 4
        ),
      },

      // Filter insect monitors with count greater than 4
      insectMonitors: {
        trapName: "Insect Monitor",
        data: result.data.insectMonitors.filter((station) => station.count > 4),
      },

      // Filter insect light traps with counts greater than 4
      insectLightTrap: {
        trapName: "Insect Light Trap",
        data: result.data.insectLightTrap.filter((insect) => {
          const keysToCheck = ["houseFly", "blowFly", "vinegarFly", "moth"];
          return keysToCheck.some((key) => insect[key] > 4);
        }),
      },

      // Filter stored product pest SPI activity with count greater than 4
      spiMonitor: {
        trapName: "Stored Product Pest SPI",
        data: result.data.storedProductPestSpiActivity.filter(
          (station) => station.count > 4
        ),
      },
    };

    //Then remove traps with no activity
    const filteredTrapActivity = {};
    for (const [key, value] of Object.entries(trapActivity)) {
      if (value.data.length > 0) {
        filteredTrapActivity[key] = value;
      }
    }

    return filteredTrapActivity;

  } catch (error) {
    console.log("Error fetching API for activity");
  } finally {
    setLoading(false);
  }

};

//get action froms linked to masterId

export const getSavedActionForSubmission = async (masterFormId) => {

  const response = await api.get(`/actionreport/submission?data.masterFormId=${masterFormId}`);

  if(response.length === 0) return [];
  return response.data;
};

