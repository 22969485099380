// App.js
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Navigation from "./components/Navigation";
import Components from "./components/MasterPage";
import Login from "./components/Auth/Login";
import GuardedRoute from "./MiddleWare/GuardedRoute";

function App() {
  return (
    <div className="App">
      <Navigation />
      <Routes>
        <Route path="/" element={<Navigate to="/components" />} />
        <Route path="/login" element={<Login />} />
        {/* <Route
          path="/components"
          element={
            <GuardedRoute>
              <Components />
            </GuardedRoute>
          }
        /> */}
        <Route
          path="/components"
          element={
            <GuardedRoute>
              <Components />
            </GuardedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
