import React, { useEffect, useState } from "react";
import * as echarts from "echarts";
import { getSubmissionsList } from "../../api";

const FliesChart = ({client, title, clientKey, threshold, graphPeriod}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [monthNames, setMonthNames] = useState([
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]);

  const flyTypes = ["houseFly", "blowFly", "moth"];
  const vinegarFlyType = "vinegarFly";

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        setLoading(true);

        const data = await getSubmissionsList(setLoading, clientKey);
        let monthCounts = {};
        let vinegarFlyMonthCounts = {};

        //go through all subs
        for(let i = 0; i < data.length; i++)
        {
          let item = data[i];
          const dateTimeVisit = new Date(item.data.dateTimeVisit);
          const month = monthNames[dateTimeVisit.getMonth()];

          const flyTraps = item.data.insectLightTrap;
          for(let j = 0; j < flyTraps.length; j++)
          {
            let flyTrap = flyTraps[j];

            for(let k = 0; k < flyTypes.length; k++)
            {
              let trap = flyTrap[flyTypes[k]];
              const count = parseInt(trap) || 0;
              monthCounts[month] = monthCounts[month] || {};
              monthCounts[month][flyTypes[k]] = (monthCounts[month][flyTypes[k]] || 0) + count;
              
              const vinegarCount = parseInt(flyTrap[vinegarFlyType]) || 0;
              vinegarFlyMonthCounts[month] = (vinegarFlyMonthCounts[month] || 0) + vinegarCount;
            }
          }
        }
        
        renderCharts(monthCounts, vinegarFlyMonthCounts);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching chart data:", error);
        setError(
          error.message || "An error occurred while fetching chart data",
        );
        setLoading(false);
      }
    };

    fetchChartData();
  }, []);

  //rule from PE there should be cobined and then a separate graph for vinegarFly
  const renderCharts = (monthCounts, vinegarFlyMonthCounts) => {
    const containerElement = document.getElementById("flies-charts-container");

    if (!containerElement) {
      console.error("Container element not found.");
      return;
    }

    //workout thresholds
    let threshSection = threshold/3;
    let threshLow = Math.ceil(threshSection);
    let threshMed = Math.ceil(threshSection)+Math.ceil(threshSection);
    let threshHigh = threshold;

    let combinedChartElementId = `combined-flies-chart`;
    let combinedChartElement = document.getElementById(combinedChartElementId);

    if (!combinedChartElement) {
      combinedChartElement = document.createElement("div");
      combinedChartElement.id = combinedChartElementId;
      combinedChartElement.style.width = "100%";
      combinedChartElement.style.height = "400px";
      combinedChartElement.style.marginBottom = "20px";
      containerElement.appendChild(combinedChartElement);
    }
    
    let monthAverages = {};
    for(let i = 0; i < monthNames.length; i++)
    {
      if(!monthAverages[monthNames[i]])
      {
        monthAverages[monthNames[i]] = 0;
      }

      let tot = 0;
      for(let j = 0; j < flyTypes.length; j++)
      {
        if(monthCounts[monthNames[i]] !== undefined)
        {
          if(monthCounts[monthNames[i]][flyTypes[j]] !== undefined)
          {
            tot = tot+monthCounts[monthNames[i]][flyTypes[j]];
          }
        }
      }
      if(flyTypes.length > 0)
      {
        monthAverages[monthNames[i]] = Math.round(tot/flyTypes.length);
      }
      else
      {
        monthAverages[monthNames[i]] = 0;
      }
    }

    let seriesData = flyTypes.map((flyType) => ({
      name: `Total Count of ${flyType}`,
      type: "bar",
      label: {
        show: true,
        formatter: function(param) {
            return param.data == 0 ? '': flyType[0].toUpperCase() + param.data;
        }
      },
      data: monthNames.map(
        (month) => {
          if(monthCounts[month] !== undefined){
            return monthCounts[month][flyType];
          }
          else{
            return 0;
          }},
      ),
      markLine: {
        silent: true,
        lineStyle: {
            color: '#333'
        },
        data: [
          {
            yAxis: threshLow
          },
          {
              yAxis: threshMed
          },
          {
              yAxis: threshHigh
          }
        ]
      }
    }));

    seriesData.push({
        name: 'Trend',
        type: 'line',
        data: monthNames.map(
          (month) => monthAverages[month],
        )
    });

    const combinedChart = echarts.init(combinedChartElement);

    combinedChart.setOption({
      title: {
        text: title+" | House Fly, Blow Fly, Moth | "+client,
      },
      tooltip: {},
      xAxis: {
        name: "Months",
        data: monthNames,
      },
      yAxis: {
        name: "Total Count",
      },
      visualMap: {
        top: 50,
        right: 10,
        pieces: [
          {
            gt: 0,
            lte: threshLow,
            color: '#93CE07'
          },
          {
              gt: threshLow,
              lte: threshMed,
              color: '#FBDB0F'
          },
          {
              gt: threshMed,
              lte: threshHigh,
              color: '#FD0100'
          },
          {
              gt: threshHigh,
              color: '#AC3B2A'
          }
        ],
        outOfRange: {
            color: '#999'
        }
      },
      series: seriesData
    });

    // Render chart for vinegar flies
    let vinegarChartElementId = `vinegar-fly-chart`;
    let vinegarChartElement = document.getElementById(vinegarChartElementId);

    if (!vinegarChartElement) {
      vinegarChartElement = document.createElement("div");
      vinegarChartElement.id = vinegarChartElementId;
      vinegarChartElement.style.width = "100%";
      vinegarChartElement.style.height = "400px";
      vinegarChartElement.style.marginBottom = "20px";
      containerElement.appendChild(vinegarChartElement);
    }

    const vinegarChart = echarts.init(vinegarChartElement);

    vinegarChart.setOption({
      title: {
        text: title+" | Vinegar Fly | "+client,
      },
      tooltip: {},
      xAxis: {
        name: "Months",
        data: monthNames,
      },
      yAxis: {
        name: `Total Count of Vinegar Fly`,
      },
      visualMap: {
          top: 50,
          right: 10,
          pieces: [
            {
              gt: 0,
              lte: threshLow,
              color: '#93CE07'
            },
            {
                gt: threshLow,
                lte: threshMed,
                color: '#FBDB0F'
            },
            {
                gt: threshMed,
                lte: threshHigh,
                color: '#FD0100'
            },
            {
                gt: threshHigh,
                color: '#AC3B2A'
            }
          ],
          outOfRange: {
              color: '#999'
          }
      },
      series: [
        {
          name: `Total Count of Vinegar Fly`,
          type: "bar",
          label: {
            show: true,
            formatter: function(param) {
                return param.data == 0 ? '': 'V' + param.data;
            }
          },
          data: monthNames.map((month) => vinegarFlyMonthCounts[month] || 0),
          markLine: {
            silent: true,
            lineStyle: {
                color: '#333'
            },
            data: [
                {
                    yAxis: threshLow
                },
                {
                    yAxis: threshMed
                },
                {
                    yAxis: threshHigh
                }
            ]
          }
        },
        {
          name: 'Trend',
          type: 'line',
          data: monthNames.map((month) => vinegarFlyMonthCounts[month] || 0),
        },
      ],
    });
  };


  return (
    <div>
      {loading && <div>Loading...</div>}
      {error && <div>Error: {error}</div>}
      <div id="flies-charts-container"></div>
    </div>
  );
};

export default FliesChart;
