import React, { useState, useEffect } from "react";
import { Container, Card, Spinner } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { getSubmissionsList, processDownloadRequest } from "../../api";
import SiteForm from "./SiteForm";
import UsageLog from "../UsageLog/UsageLog";
import Recommendation from "../Recommendation/Recommendation";
import Action from "../Action/Action";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { ArrowBack } from "@mui/icons-material";
import { Pagination, MenuItem, FormControl, Select, InputLabel } from "@mui/material";


const SubmissionsList = () => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState(null);
  const [selectedRecomendationId, setRecomendationId] = useState(null);
  const [filterValue, setFilterValue] = useState("");
  const [mode, setMode] = useState("List");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [client, setClient] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5); // State for items per page
  const pageLimitOptions = [5, 10, 20, 50, 100]; // Options for items per page

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const submissionsData = await getSubmissionsList(setLoading);
        setSubmissions(submissionsData);
      } catch (error) {
        setError(`Error fetching submissions: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchSubmissions();
  }, []);

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const handleDownload = async (submissionId, formId) => {
    console.log("Downloading submission with ID:", submissionId);
    console.log("Downloading Form with ID:", formId);

    await processDownloadRequest(formId, submissionId, setLoading);
  };

  const handleRowClick = (submissionId) => {
    setSelectedSubmissionId(submissionId);
    setMode("Edit");
  };

  const handleAction = (submissionId) => {
    setSelectedSubmissionId(submissionId);
    setMode("Action");
  };

  const handleRecommendation = (submissionId) => {
    setMode("Recommendation");
    setRecomendationId(submissionId);
  };

  const handleUsageLog = (submissionId, client) => {
    setMode("Usage");
    setClient(client);
  };

  const handleBackToList = () => {
    setMode("List");
    setSelectedSubmissionId(null);
  };

  const handleNewForm = () => {
    setSelectedSubmissionId(null);
    setMode("NewForm");
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handlePageLimitChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  const filteredSubmissions = submissions.filter((submission) =>
    submission.data.clientName.toLowerCase().includes(filterValue.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const pagenatedSubmissions = filteredSubmissions.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  return (
    <Container fluid className="mb-2">
      {mode !== "List" && (
        <Container fluid className="mt-3 mb-3">
          <Fab color="primary" size="medium" onClick={handleBackToList}>
            <ArrowBack />
          </Fab>
          <span className="ml-3">SITE FORM LIST</span>
        </Container>
      )}
      {(mode === "List" || mode === "Edit") && (
        <Card>
          <Card.Body>
            {selectedSubmissionId ? (
              <Card.Title style={{ textAlign: "center" }}>
                EDIT SITE FORM{" "}
              </Card.Title>
            ) : (
              <>
                <Card.Title style={{ textAlign: "center" }}>
                  SITE FORM LIST
                </Card.Title>
                <div className="col-md-6 mb-3" style={{width:"100%"}}>
                  <TextField
                    id="outlined-basic"
                    label="Search"
                    size="small"
                    variant="outlined"
                    value={filterValue}
                    onChange={(e) => setFilterValue(e.target.value)}
                  />
                  <Fab
                    style={{float:"right"}}
                    size="medium"
                    color="primary"
                    aria-label="add"
                    variant="extended"
                    onClick={handleNewForm}
                  >
                    <AddIcon sx={{ mr: 1 }}/>
                    Add
                  </Fab>
                </div>
              </>
            )}
            {loading && (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            {!loading && !error && (
              <>
                {selectedSubmissionId ? (
                  <SiteForm submissionId={selectedSubmissionId} />
                ) : (
                  <div>
                    <Table
                      className="table table-striped"
                      style={{ minWidth: "fit-content" }}
                    >
                      <Thead>
                        <Tr>
                          <Th onClick={() => handleSort("clientName")}>
                            Client Name{" "}
                            {sortField === "clientName" &&
                              (sortDirection === "asc" ? "▲" : "▼")}
                          </Th>
                          <Th onClick={() => handleSort("dateTimeVisit")}>
                            Date{" "}
                            {sortField === "dateTimeVisit" &&
                              (sortDirection === "asc" ? "▲" : "▼")}
                          </Th>
                          <Th>Telephone</Th>
                          <Th>Status</Th>
                          <Th>Form Links</Th>
                          <Th>Download</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {pagenatedSubmissions.map((submission, index) => (
                          <Tr
                            key={index}
                            className={index % 2 === 0 ? "even" : "odd"}
                          >
                            <Td
                              onClick={() => handleRowClick(submission._id)}
                              style={{ cursor: "pointer" }}
                            >
                              {submission.data.clientName}
                            </Td>
                            <Td
                              onClick={() => handleRowClick(submission._id)}
                              style={{ cursor: "pointer" }}
                            >
                              {new Date(
                                submission.data.dateTimeVisit,
                              ).toLocaleDateString(undefined, {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              })}
                            </Td>
                            <Td
                              onClick={() => handleRowClick(submission._id)}
                              style={{ cursor: "pointer" }}
                            >
                              {submission.data.tel}
                            </Td>
                            <Td
                              onClick={() => handleRowClick(submission._id)}
                              style={{ cursor: "pointer" }}
                            >
                              {submission.data.isCompleted === "True" ? (
                                <CheckCircleOutlineIcon />
                              ) : (
                                "IN PROGRESS"
                              )}
                            </Td>
                            <Td>
                              <div
                                className="d-flex flex-column flex-sm-row"
                                style={{
                                  gap: "3px",
                                  minWidth: "fit-content",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  color="error"
                                  size="small"
                                  onClick={() => handleAction(submission._id)}
                                >
                                  Action
                                </Button>
                                <Button
                                  variant="contained"
                                  color="success"
                                  size="small"
                                  style={{ minWidth: "fit-content" }}
                                  onClick={() =>
                                    handleRecommendation(submission._id)
                                  }
                                >
                                  Recommendation
                                </Button>
                                <Button
                                  variant="contained"
                                  size="small"
                                  onClick={() =>
                                    handleUsageLog(
                                      submission._id,
                                      submission.data.clientName,
                                    )
                                  }
                                >
                                  Usage Log
                                </Button>
                              </div>
                            </Td>
                            <Td style={{ textAlign: "center" }}>
                              <Button
                                variant="contained"
                                size="small"
                                onClick={() =>
                                  handleDownload(
                                    submission._id,
                                    submission.form,
                                  )
                                }
                              >
                                <DownloadForOfflineIcon />
                              </Button>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                    <div className="d-flex justify-content-center mt-3 mb-3">
                      <Pagination
                        count={Math.ceil(
                          filteredSubmissions.length / itemsPerPage,
                        )}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                      />
                      <div className="ml-2 d-flex align-items-center">
                        <InputLabel style={{ marginRight: "8px" }}>
                          Page Items
                        </InputLabel>
                        <FormControl
                          sx={{ minWidth: 60 }}
                          size="small"
                          className="ml-2"
                        >
                          <Select
                            value={itemsPerPage}
                            onChange={handlePageLimitChange}
                            displayEmpty
                          >
                            {pageLimitOptions.map((option, index) => (
                              <MenuItem key={index} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      )}

      {mode === "NewForm" && <SiteForm />}
      {mode === "Action" && <Action submissionId={selectedSubmissionId} />}
      {mode === "Recommendation" && (
        <Recommendation submissionId={selectedRecomendationId} />
      )}
      {mode === "Usage" && (
        <UsageLog submissionId={selectedSubmissionId} client={client} />
      )}
    </Container>
  );
};

export default SubmissionsList;
