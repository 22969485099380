// GuardedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";

const isAuthenticated = () => {
  const token = localStorage.getItem("jwtToken");
  const userRole = localStorage.getItem("role");

  return !!token && !!userRole;
};

const GuardedRoute = ({ children, fallbackPath = "/login" }) => {
  return isAuthenticated() ? children : <Navigate to={fallbackPath} />;
};

export default GuardedRoute;
