import { Form } from "@formio/react";
import { API_BASE_URL } from "../../Util/constants";

const Users = ({ submissionId }) => {
  const formName = "pesteliteusers";

  const onSubmitHandler = (submission) => {
    console.log("Form submitted");
  };

  return (
    <>
      <div className="pt-3">
        <div className="card p-2">
          <div className="card-body bg-light rounded-3">
            {submissionId ? (
              <Form
                src={`${API_BASE_URL}/${formName}/submission/${submissionId}`}
                onSubmit={(submission) => onSubmitHandler(submission)}
                // onChange={onChangeHandler}
              />
            ) : (
              <Form
                src={`${API_BASE_URL}/${formName}`}
                onSubmit={(submission) => onSubmitHandler(submission)}
                // onChange={onChangeHandler}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
