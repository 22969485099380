import { Container } from "react-bootstrap";
import { Form } from "@formio/react";
import { API_BASE_URL } from "../../Util/constants";

const Bookings = ({ submissionId }) => {
  const formName = "pebooking";

  const onSubmitHandler = (submission) => {
    console.log(submission);
  };

  const onChangeHandler = (event) => {
    console.log("Form value changed:", event);
  };

  return (
    <Container fluid>
      <div className="pt-3">
        <div className="card p-2">
          <div className="card-body bg-light rounded-3">
            {submissionId ? (
              <Form
                src={`${API_BASE_URL}/${formName}/submission/${submissionId}`}
                onSubmit={onSubmitHandler}
                onChange={onChangeHandler}
              />
            ) : (
              <Form
                src={`${API_BASE_URL}/${formName}`}
                onSubmit={onSubmitHandler}
                onChange={onChangeHandler}
              />
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Bookings;
