import React, { useState, useEffect } from "react";
import { Container, Card, Image } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { getPesticidesList } from "../../api";
import Pesticide from "./Pesticides";
import { ADMIN } from "../../Util/constants";
import TextField from "@mui/material/TextField";
import pestEliteLogo from "../../styles/PE_Logo-removebg-preview-e1659271343336.png";
import { Pagination, MenuItem, FormControl, Select, InputLabel } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";

const PesticidesSubmission = ({ submissionId }) => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5); // should I do 10?
  const pageLimitOptions = [5, 10, 20, 50, 100]; // maybe include option to max

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const submissionsData = await getPesticidesList(setLoading);
        setSubmissions(submissionsData);
      } catch (error) {
        setError(`Error fetching submissions: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchSubmissions();
  }, []);

  const isAdmin = localStorage.getItem("role") === ADMIN;

  const handleRowClick = (submissionId) => {
    setSelectedSubmissionId(submissionId);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handlePageLimitChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1); 
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // reset on search
  };

  const handleBackToList = () => {
    setSelectedSubmissionId(null);
  };

  const filteredSubmissions = submissions.filter((submission) => {
     return (
      submission.data.pesticide.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const pagenatedSubmissions = filteredSubmissions.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  return (
    <Container fluid className="mb-2">
      <div className="formio-brand container-fluid">
        <h3>PESTICIDES</h3>
        <Image
          src={pestEliteLogo}
          alt="Pest Elite Logo"
          fluid
          className="m-2"
          style={{ width: "70px", height: "auto" }}
        />
      </div>
      <Card>
        <Card.Body>
          {selectedSubmissionId ? (
            <Card.Title style={{ textAlign: "center" }}>
              EDIT PESTICIDE
            </Card.Title>
          ) : (
            <Card.Title style={{ textAlign: "center" }}>
              PESTICIDE LIST
            </Card.Title>
          )}
          {loading && <p>Loading...</p>}
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          {!loading && !error && (
            <>
              {selectedSubmissionId ? (
                <>
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    onClick={handleBackToList}
                    style={{ cursor: "pointer" }}
                  />
                  {selectedSubmissionId === true ? (
                    <Pesticide />
                  ) : (
                    <Pesticide submissionId={selectedSubmissionId} />
                  )}
                </>
                
              ) : (
                <>
                  <div className="col-md-6 mb-3" style={{width:"100%"}}>
                    <TextField
                      id="outlined-basic"
                      label="Search"
                      size="small"
                      variant="outlined"
                      value={searchQuery}
                      onChange={handleSearch}
                    />
                    {isAdmin &&
                      <Fab
                        style={{float:"right"}}
                        size="medium"
                        color="primary"
                        aria-label="add"
                        variant="extended"
                        onClick={() => handleRowClick(true)}
                      >
                        <AddIcon sx={{ mr: 1 }}/>
                        Add
                      </Fab>
                    }
                  </div>
                  <Table className="table-bordered table-striped rounded">
                    <Thead>
                      <Tr>
                        <Th style={{width: "40px"}}>No.</Th>
                        <Th>Pesticide Name</Th>
                        <Th>Type</Th>
                        <Th>Reg No.</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {pagenatedSubmissions.map((submission, index) => (
                        <Tr
                          key={index}
                          onClick={
                            isAdmin
                              ? () => handleRowClick(submission._id)
                              : null
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <Td>{indexOfFirstItem + index + 1}</Td>
                          <Td>{submission.data.pesticide}</Td>
                          <Td>{submission.data.type}</Td>
                          <Td>{submission.data.regNo}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                  <div className="d-flex justify-content-center mt-3 mb-3">
                    <Pagination
                      count={Math.ceil(
                        filteredSubmissions.length / itemsPerPage
                      )}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                    />
                    <div className="ml-2 d-flex align-items-center">
                      <InputLabel style={{ marginRight: '8px' }}>Page Items</InputLabel>
                      <FormControl  sx={{ minWidth: 60 }} size="small" className="ml-2">
                        <Select
                          value={itemsPerPage}
                          onChange={handlePageLimitChange}
                          displayEmpty
                        >
                          {pageLimitOptions.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PesticidesSubmission;
