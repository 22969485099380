import { Form } from "@formio/react";
import { API_BASE_URL } from "../../Util/constants";
import { Container, Spinner, Alert } from "react-bootstrap";
import { getRecommendation, updateMasterFormId } from "../../api";
import React, { useState, useEffect } from "react";

const Recommendation = ({ submissionId }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState(null);
  const formName = "recommendation-technician";

  const onSubmitHandler = async (submission) => {
    const data =  [{
      op: "replace",
      path: "/data/masterFormId", 
      value: submissionId
    }];
    await updateMasterFormId(data, setLoading, submission._id, "recommendation");
  };

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const submissionsData = await getRecommendation(setLoading, submissionId);
        if (submissionsData.length > 0) {
          setSelectedSubmissionId(submissionsData[0]._id);
        }
      } catch (error) {
        setError(`Error fetching submissions: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchSubmissions();
  }, [submissionId]);


  return (
    <Container fluid>
      <div className="pt-3">
        <div className="card p-2">
          <div className="card-body bg-light rounded-3">
            {loading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              
            ) : error ? (
              <Alert variant="danger">{error}</Alert>
            ) : (
              <Form
                src={`${API_BASE_URL}/${formName}${selectedSubmissionId ? `/submission/${selectedSubmissionId}` : ''}`}
                onSubmit={onSubmitHandler}
              />
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Recommendation;
