import { Form } from "@formio/react";
import { API_BASE_URL } from "../../Util/constants";
import { Container, Spinner } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import {
  getActivity,
  getSavedActionForSubmission,
  updateMasterFormId,
} from "../../api";

const Action = ({ submissionId }) => {
  const formName = "actionreport";
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [allEmpty, setAllEmpty] = useState(true);
  const [savedActionReports, setSavedActionReports]  = useState([]);
  const [toDoActionReports, setToDoActionReports] = useState([]);
  useEffect(() => {
    const fetchActivity = async () => {
      try {
        let activityData = await getActivity(setLoading, submissionId);
        setFormData(activityData);
        const allEmpty = allArraysEmpty(formData);

        if(allEmpty) {
        setAllEmpty(allEmpty);
        }else{
          //fetch all saved with this submissionID
          //api get all submissions from action with masterId
          const savedActionReports = await getSavedActionForSubmission(
            submissionId
          );
          //trapName
          const toDoActionItems = [];
          for (const [key, value] of Object.entries(activityData)) {
            const trapName = value.trapName;

            // Check if trapName exists in savedActionReports
            const trapNameExists = savedActionReports.some(
              (submission) => submission.data.trapName === trapName
            );

            // If trapName does not exist in savedActionReports, push it into toDoActionReports
            if (!trapNameExists) {
              toDoActionItems.push({ trapName });
            }
          }
          setSavedActionReports(savedActionReports);
          setToDoActionReports(toDoActionItems);
       
        }

        setToDoActionReports(toDoActionReports);
      } catch (error) {
        setError(`Error fetching submissions: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchActivity();
  }, []);

  const onSubmitHandler = async (submission) => {
    console.log(submission);
    //set masterFormId  = submissionId
      const data = [
        {
          op: "replace",
          path: "/data/masterFormId",
          value: submissionId,
        },
      ];
    await updateMasterFormId(data, setLoading, submission._id, "recommendation");

  };

  //fresh new submission without any activity or has actually no activity
  function allArraysEmpty(obj) {
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const array = obj[key];
        if (array.length !== 0) {
          return false;
        }
      }
    }
    return true;
  }

  //going to iterate through the checklist to add the number of forms but if there exist forms with subId of main form load them

  return (
    <Container fluid>
      <div className="pt-3">
        <div className="card p-2">
          {loading && (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          )}
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          {!loading && !error && (
            <div className="card-body bg-light rounded-3">
              {allEmpty === true ? (
                <p>No activity recorded at site</p>
              ) : (
                <div>
                  <h1>Required Actions</h1>
                  {toDoActionReports.map((report, index) => (
                    <div key={index}>
                      <h3>{report.trapName}</h3>
                      <Form
                        src={`${API_BASE_URL}/${formName}`}
                        onSubmit={onSubmitHandler}
                      />
                    </div>
                  ))}
                  <h1>Saved Action Forms</h1>
                  {savedActionReports.map((report, index) => (
                    <div key={index}>
                      <h3>{report.data.trapName}</h3>
                      <Form
                        src={`${API_BASE_URL}/${formName}/submission/${submissionId}`}
                        onSubmit={onSubmitHandler}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Action;
