import { Form } from "@formio/react";
import { API_BASE_URL } from "../../Util/constants";
import { updateMasterFormId } from "../../api";
import React, { useState } from "react";

const SiteForm = ({ submissionId, bookingRef }) => {
  const formName = "pesiteform";
  const [loading, setLoading] = useState(true);

  const onSubmitHandler = async (submission) => {
    const newSubmissionId = submission._id;
    //create its recommedation with masterId of newsubmissionId or if exists leave it
   // await checkIfSubmissionExists(setLoading, newSubmissionId);
    //IF exist => create new submission

    //create its action if it doesn't exist yet, else update it

    //if(form booking update booking ref)
    if(bookingRef){
      //update booking ref with the recieved ref
        const data = [
          {
            op: "replace",
            path: "/data/bookingRef",
            value: bookingRef,
          },
        ];
        await updateMasterFormId(
          data,
          setLoading,
          submission._id,
          "service",
        );
    }
  };

  return (
    <>
      <div className="pt-3">
        <div className="card p-2">
          <div className="card-body bg-light rounded-3">
            {submissionId ? (
              <Form
                src={`${API_BASE_URL}/${formName}/submission/${submissionId}`}
                onSubmit={onSubmitHandler}
              />
            ) : (
              // Render the default form for new mode
              <Form
                src={`${API_BASE_URL}/${formName}`}
                onSubmit={onSubmitHandler}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteForm;
