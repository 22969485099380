import { Form } from "@formio/react";
import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../Util/constants";
import { getUsageLogs } from "../../api";
import { Spinner } from "react-bootstrap";

const UsageLog = ({ submissionId, client }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState(submissionId);
  const formName = "peusagereport";

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const submissionsData = await getUsageLogs(setLoading, client);
        //setSubmissions(submissionsData);

        if (client) {
          setSelectedSubmissionId(submissionsData[0]._id);
        }
      } catch (error) {
        setError(`Error fetching submissions: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchSubmissions();
  }, []);

  const onSubmitHandler = (submission) => {
    console.log(submission);
  };

  return (
    <>
      {loading ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <div className="pt-3">
          <div className="card p-2">
            <div className="card-body bg-light rounded-3">
              {selectedSubmissionId ? (
                <Form
                  src={`${API_BASE_URL}/${formName}/submission/${selectedSubmissionId}`}
                  onSubmit={onSubmitHandler}
                />
              ) : (
                <Form
                  src={`${API_BASE_URL}/${formName}`}
                  onSubmit={onSubmitHandler}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UsageLog;
