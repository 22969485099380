import React, { useState, useEffect, useRef } from "react";
import { Container, Card, Spinner, Image, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { getClientList, getSubmissionsList } from "../../api";
import Clients from "./Clients";
import Graphs from "../Charts/Graphs";
//import RhodentsChart from "../Charts/Rhodents";
import FliesChart from "../Charts/Flies";
import { ADMIN } from "../../Util/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import pestEliteLogo from "../../styles/PE_Logo-removebg-preview-e1659271343336.png";
import { Pagination, MenuItem, FormControl, Select, InputLabel } from "@mui/material";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import jsPDF from "jspdf"; 
import Button from "@mui/material/Button";
import html2canvas from "html2canvas";
import Fab from "@mui/material/Fab";

const ClientSubmissions = () => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState(null);
  const [graphData, setGraphData] = useState(null);//tracks data used in all the graphs
  const [graphPeriod, setGraphPeriod] = useState("Monthly");//6 Month, Monthly, Weekly, Bi-Weekly
  const [graphRange, setGraphRange] = useState({"start": false, "end": (new Date()).toISOString().split('T')[0]});
  const [graphThresholds, setGraphThresholds] = useState({
    "flyingInsectsThreshold": 100,
    "cockroachThreshold": 100,
    "rodentThreshold": 50
  });//tracks data used in all the graphs
  const [listMode, setListMode] = useState(true); // State to track list mode
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5); // Number of items to display per page
  const pageLimitOptions = [5, 10, 20, 50, 100]; 
  const [clientName, setClientName] = useState("");
  const containerRef = useRef(null);

  const isAdmin = localStorage.getItem("role") === ADMIN;

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const submissionsData = await getClientList(setLoading);
        setSubmissions(submissionsData);
      } catch (error) {
        setError(`Error fetching submissions: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchSubmissions();
  }, []);

  const handleRowClick = (submissionId) => {
    setSelectedSubmissionId(submissionId);
  };

  const handleDateChange = (type, val) => {
    let newRange = graphRange;
    if(type === "start")
    {
      newRange["start"] = val.target.value;
    }
    else if(type === "end")
    {
      newRange["end"] = val.target.value;
    }
    setGraphRange(newRange);
    getGraphData();
  }

  const handleGraphView = (submissionId, client) => {
    setLoading(true);
    setListMode(false);
    setClientName(client);
    setSelectedSubmissionId(submissionId);

    getGraphData();
  };

  const getGraphData = async() => {
    let traps=[ //define what traps we want data from
      "baitStations",
      "mechanicalTraps", 
      "noneToxicTraps", 
      "cartonTraps",
      "generalAreaCockroaches",
      "insectMonitors",
      "insectLightTrap",
      "redTop",
      "aerosol",
      "storedProductPestSpiActivity"
    ];
    let thresholds=graphThresholds;
    const data = await getSubmissionsList(setLoading, selectedSubmissionId);

    let newData = {};

    //instantiate our data out
    for(let i = 0; i < traps.length; i++)
    {
      newData[traps[i]] = [];
    }

    //loop through the data and sort it into trap types by date
    let latestDate = 0;
    for(let i = 0; i < data.length; i++)
    {
      const dateTimeVisit = data[i].data.dateTimeVisit.split("T")[0];
      if(dateTimeVisit.replaceAll("-", "") === latestDate || dateTimeVisit.replaceAll("-", "") > latestDate)
      {
        latestDate = dateTimeVisit.replaceAll("-", "");
        if(data[i].data.selectClient.data.flyingInsectsThreshold)
        {
          thresholds = {
            "flyingInsectsThreshold": data[i].data.selectClient.data.flyingInsectsThreshold,
            "cockroachThreshold": data[i].data.selectClient.data.cockroachThreshold,
            "rodentThreshold": data[i].data.selectClient.data.rodentThreshold
          };
        }
      }
      
      //if start date is set and submission date is bigger/equal(or if start date is not set)
      if((graphRange["start"] !== false && dateTimeVisit.replaceAll("-", "") >= graphRange["start"].replaceAll("-", "")) || graphRange["start"] === false)
      {
        //if end date is set and submission date is smaller/equal(or if end date is not set)
        if((graphRange["end"] !== false && dateTimeVisit.replaceAll("-", "") <= graphRange["end"].replaceAll("-", "")) || graphRange["end"] === false)
        {
          for(let j = 0; j < traps.length; j++)
          {
            if(!newData[traps[j]][dateTimeVisit]){
              newData[traps[j]][dateTimeVisit] = [];
            }
            
            for(let k = 0; k < data[i].data[traps[j]].length; k++)
            {
              newData[traps[j]][dateTimeVisit].push(data[i].data[traps[j]][k]);
            }
          }
        }
      }
    }
    console.log(newData);
    
    setGraphThresholds(thresholds);
    setGraphData(newData);
    setLoading(false);
  }

  const handleBackToList = () => {
    setListMode(true);
    setSelectedSubmissionId(null);
    setGraphData(null);
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // reset on search
  };

  // Sorting
  const sortedSubmissions = [...submissions].sort((a, b) => {
    if (sortField === "clientName") {
      return sortDirection === "asc"
        ? (a.data.clientName || '').localeCompare((b.data.clientName || ''))
        : (b.data.clientName || '').localeCompare((a.data.clientName || ''));
    }
    return 0;
  });

  // Filter submissions
  const filteredSubmissions = sortedSubmissions.filter((submission) =>
    (submission.data.clientName || '').toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginatedSubmissions = filteredSubmissions.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const pageCount = Math.ceil(filteredSubmissions.length / itemsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1); 
  };

  //download the canvas
  const handleDownloadPDF = () => {
    const input = containerRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      //pdf.setFontSize(9);
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const numPages = Math.ceil(imgHeight/pageHeight);
      let heightLeft = imgHeight;
      let position = 0;

      for(let currentPage = 0; currentPage < numPages; currentPage++)
      {
        if(currentPage !== 0)
        {
          pdf.addPage();
        }
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        pdf.text('Page ' + (currentPage+1) + ' of ' + numPages, pdf.internal.pageSize.getWidth() - 20, pdf.internal.pageSize.getHeight() - 10);
        heightLeft -= pageHeight;
        position = heightLeft - imgHeight;
      }

      pdf.save('graph.pdf');
    });
  };
   
  return (
    <Container fluid className="mt-2 mb-2">
      <div className="formio-brand container-fluid">
        <h3>CLIENTS</h3>
        <Image
          src={pestEliteLogo}
          alt="Pest Elite Logo"
          fluid
          className="m-2"
          style={{ width: "70px", height: "auto" }}
        />
      </div>
      {listMode && (
        <Card>
          <Card.Body>
            {selectedSubmissionId ? (
              <Card.Title style={{ textAlign: "center" }}>
                EDIT CLIENT
              </Card.Title>
            ) : (
              <Card.Title style={{ textAlign: "center" }}>
                CLIENT LIST
              </Card.Title>
            )}
            {loading && (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            {!loading && !error && (
              <>
                {selectedSubmissionId ? (
                  <>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      onClick={handleBackToList}
                      style={{ cursor: "pointer" }}
                    />
                    {selectedSubmissionId === true ? (
                      <Clients />
                    ) : (
                      <Clients submissionId={selectedSubmissionId} />
                    )}
                  </>
                ) : (
                  <div>
                    <div className="col-md-6 mb-3" style={{width:"100%"}}>
                      <TextField
                        id="outlined-basic"
                        label="Search"
                        size="small"
                        variant="outlined"
                        value={searchQuery}
                        onChange={handleSearch}
                      />
                      {isAdmin &&
                        <Fab
                          style={{float:"right"}}
                          size="medium"
                          color="primary"
                          aria-label="add"
                          variant="extended"
                          onClick={() => handleRowClick(true)}
                        >
                          <AddIcon sx={{ mr: 1 }}/>
                          Add
                        </Fab>
                      }
                    </div>
                    <Table className="table-bordered table-striped rounded">
                      <Thead>
                        <Tr>
                          <Th style={{width: "40px"}}>No.</Th>
                          <Th onClick={() => handleSort("clientName")}>
                            Client Name{" "}
                            {sortField === "clientName" &&
                              (sortDirection === "asc" ? "▲" : "▼")}
                          </Th>
                          <Th>Telephone</Th>
                          <Th>Next Service Due</Th>
                          <Th>Analytics</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {paginatedSubmissions.map((submission, index) => (
                          <Tr
                            key={index}
                            onClick={
                              isAdmin
                                ? () => handleRowClick(submission._id)
                                : null
                            }
                            style={{ cursor: isAdmin ? "pointer" : "default" }}
                          >
                            <Td>{indexOfFirstItem + index + 1}</Td>
                            <Td>{submission.data.clientName ? submission.data.clientName : ''}</Td>
                            <Td>{submission.data.telephone ? submission.data.telephone : ''}</Td>
                            <Td>
                              {submission.data.nextServiceDue
                                ? new Date(
                                    submission.data.nextServiceDue,
                                  ).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  })
                                : "Not Stated"}
                            </Td>
                            <Td
                              onClick={() =>
                                handleGraphView(
                                  submission._id,
                                  submission.data.clientName || '',
                                )
                              }
                            >
                              <BarChartOutlinedIcon />
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                    <div className="d-flex justify-content-center mt-3 mb-3">
                      <Pagination
                        count={pageCount}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                      />
                      <div className="ml-2 d-flex align-items-center">
                        <InputLabel style={{ marginRight: "8px" }}>
                          Page Items
                        </InputLabel>
                        <FormControl
                          sx={{ minWidth: 60 }}
                          size="small"
                          className="ml-2"
                        >
                          <Select
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                            displayEmpty
                          >
                            {pageLimitOptions.map((option, index) => (
                              <MenuItem key={index} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      )}

      {!listMode && (
        <Container fluid className="mt-3">
          <FontAwesomeIcon
            icon={faArrowLeft}
            onClick={handleBackToList}
            style={{ cursor: "pointer" }}
          />{" "}
          Client List
          <Button
            variant="contained"
            color="error"
            size="medium"
            onClick={() => handleDownloadPDF()}
            className="m-3"
            disabled={loading}
          >
            Download Graphs
          </Button>
          
          <ToggleButtonGroup type="radio" name="Period" value={graphPeriod} onChange={(val, event) => { setGraphPeriod(val); getGraphData();}} defaultValue={"Monthly"}>
            <ToggleButton id="tbg-btn-1" variant="secondary" value={"6 Month"}>6 Month</ToggleButton>
            <ToggleButton id="tbg-btn-2" variant="secondary" value={"Monthly"}>Monthly</ToggleButton>
            <ToggleButton id="tbg-btn-3" variant="secondary" value={"Weekly"}>Weekly</ToggleButton>
            <ToggleButton id="tbg-btn-4" variant="secondary" value={"Bi-Weekly"}>Bi-Weekly</ToggleButton>
          </ToggleButtonGroup>
          <span style={{"paddingLeft": "10px"}}> 
            <span>Start <input type="date" id="graphStart" className="form-control" style={{"width": "150px", "display": "inline"}} value={graphRange["start"]} onChange={(val) => handleDateChange("start", val)}/></span>
            <span style={{"paddingLeft": "10px"}}>End <input type="date" id="graphEnd" className="form-control" style={{"width": "150px", "display": "inline"}} value={graphRange["end"]} onChange={(val) => handleDateChange("end", val)}/></span>
          </span>
          <Container fluid ref={containerRef}>
            <div className="m-3">
              <Graphs client={clientName} title="Rodent Activity" threshold={graphThresholds["rodentThreshold"]}
                graphPeriod={graphPeriod}
                data={{
                  "Bait Stations": graphData?.baitStations,
                  "Mechanical Traps": graphData?.mechanicalTraps,
                  "Non-Toxic Traps": graphData?.noneToxicTraps,
                  "Carton Traps": graphData?.cartonTraps,
                }} 
              />
            </div>
            <div className="m-3">
              <FliesChart client={clientName} title="Insect Activity" threshold={graphThresholds["flyingInsectsThreshold"]}
                graphPeriod={graphPeriod}
                clientKey={selectedSubmissionId} 
              />
            </div>
            <div className="m-3">
              <Graphs client={clientName} title="Insect Activity" threshold={graphThresholds["flyingInsectsThreshold"]} 
                graphPeriod={graphPeriod}
                data={{
                  "Red Top": graphData?.redTop,
                }} 
              />
            </div>
            <div className="m-3">
              <Graphs client={clientName} title="Cockroach Activity" threshold={graphThresholds["cockroachThreshold"]}
                graphPeriod={graphPeriod}
                data={{
                  "Inspected": graphData?.generalAreaCockroaches,
                  "Insect Monitor": graphData?.insectMonitors,
                }} 
              />
            </div>
          </Container>
        </Container>
      )}
    </Container>
  );
};

export default ClientSubmissions;
