import React, { useState, useEffect } from "react";
import { Container, Card, Spinner, Image } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { getUsageLogs } from "../../api";
import moment from "moment";
import pestEliteLogo from "../../styles/PE_Logo-removebg-preview-e1659271343336.png";
import TextField from "@mui/material/TextField";
import { Pagination, MenuItem, FormControl, Select, InputLabel } from "@mui/material";

const UsageLogSubmissions = ({ submissionId, handleTabChanges }) => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState(null);
  const [searchClient, setSearchClient] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const pageLimitOptions = [5, 10, 20, 50, 100];

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const submissionsData = await getUsageLogs(setLoading);
        setSubmissions(submissionsData);
      } catch (error) {
        setError(`Error fetching submissions: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchSubmissions();
  }, []);

  const handleRowClick = (submissionId) => {
    setSelectedSubmissionId(submissionId);
    console.log(selectedSubmissionId);
  };

  const handleSearch = (e) => {
    setSearchClient(e.target.value);
    setCurrentPage(1); 
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handlePageLimitChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1); // default to page 1
  };

  const filteredSubmissions = searchClient
    ? submissions.filter((submission) =>
        submission.data.client.toLowerCase().includes(searchClient.toLowerCase())
      )
    : submissions;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredSubmissions.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <Container fluid className="mb-2">
      <div className="formio-brand container-fluid">
        <h3>USAGE LOG</h3>
        <Image
          src={pestEliteLogo}
          alt="Pest Elite Logo"
          fluid
          className="m-2"
          style={{ width: "70px", height: "auto" }}
        />
      </div>
      <Card>
        <Card.Body>
          <Card.Title style={{ textAlign: "center" }}>
            USAGE LOG
          </Card.Title>
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-3">
            <div className="m-2">
              <TextField
                id="outlined-basic"
                label="Search"
                size="small"
                variant="outlined"
                value={searchClient}
                onChange={handleSearch}
              />
            </div>
          </div>
          {loading ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <>
              <div className="table-responsive">
                <Table className="table-bordered table-striped">
                  <Thead>
                    <Tr>
                      <Th>Client Name</Th>
                      <Th>Date</Th>
                      <Th>Pesticide</Th>
                      <Th>Type</Th>
                      <Th>Reg.</Th>
                      <Th>Batch No.</Th>
                      <Th>Quantity</Th>
                      <Th>Units</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {currentItems.map((submission, index) => (
                      <React.Fragment key={index}>
                        {submission.data.usageLog.map((log, id) => (
                          <Tr key={id} onClick={() => handleRowClick(submission._id)}>
                            <Td>{submission.data.client}</Td>
                            <Td>{moment(log.dateTime).format("YYYY-MM-DD")}</Td>
                            <Td>{log.pesticideTradeName.data.pesticide}</Td>
                            <Td>{log.pesticideTradeName.data.type}</Td>
                            <Td>{log.pesticideTradeName.data.regNo}</Td>
                            <Td>{log.batchNo}</Td>
                            <Td>{log.quantityUsed}</Td>
                            <Td>{log.units}</Td>
                          </Tr>
                        ))}
                      </React.Fragment>
                    ))}
                  </Tbody>
                </Table>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <Pagination
                  count={Math.ceil(filteredSubmissions.length / itemsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                />
                <div className="ml-2 d-flex align-items-center">
                  <InputLabel style={{ marginRight: '8px' }}>Page Items</InputLabel>
                  <FormControl  sx={{ minWidth: 60 }} size="small" className="ml-2">
                    <Select
                      value={itemsPerPage}
                      onChange={handlePageLimitChange}
                      displayEmpty
                    >
                      {pageLimitOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default UsageLogSubmissions;
