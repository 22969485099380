import { Card, Image } from "react-bootstrap";
import SubmissionsList from "./SiteForms/SiteFormSubmissions";
import pestEliteLogo from "../styles/PE_Logo-removebg-preview-e1659271343336.png";

const Dashboard = () => {
  const headingStyle = {
    fontFamily: "'Roboto', sans-serif",
  };

  return (
    <Card.Body>
      <div className="formio-brand container-fluid">
        <h3 style={headingStyle}>DASHBOARD</h3>
        <div className="formio-brand">
          <Image
            src={pestEliteLogo}
            alt="Pest Elite Logo"
            fluid
            className="m-2"
            style={{ width: "70px", height: "auto" }}
          />
        </div>
      </div>
      <SubmissionsList />
    </Card.Body>
  );
};

export default Dashboard;
