import { Container, Nav, Navbar, Image } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import pestEliteLogo from "../styles/PE_Logo-removebg-preview-e1659271343336.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserShield, faUserCog } from "@fortawesome/free-solid-svg-icons";
import { ADMIN } from "../Util/constants";

function Navigation() {
  const headingStyle = {
    fontFamily: "'Roboto', sans-serif",
  };

  const navigate = useNavigate();
  const location = useLocation();
  const showNavigation = !["/login", "/register"].includes(location.pathname);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const isAdmin = localStorage.getItem("role") === ADMIN;

  return (
    showNavigation && (
      <Navbar
        className="d-none d-lg-block"
        bg="light"
        expand="lg"
        style={headingStyle}
      >
        <Container fluid>
          <Navbar.Brand>
            <Link to={"/"} className="text-decoration-none">
              <div className="formio-brand">
                <Image
                  src={pestEliteLogo}
                  alt="Pest Elite Logo"
                  fluid
                  className="me-2"
                  style={{ width: "60px", height: "auto" }}
                />
              </div>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav style={headingStyle}>
              <Nav.Link onClick={handleLogout} className="nav-link">
                {isAdmin ? (
                  <>
                    <FontAwesomeIcon icon={faUserShield} className="me-1" />
                    <b>LOGOUT (Admin)</b>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faUserCog} className="me-1" />
                    <b>LOGOUT(Technician)</b>
                  </>
                )}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  );
}

export default Navigation;
