import React, { useEffect, useState } from "react";
import * as echarts from "echarts";
import { getSubmissionsList } from "../../api";

//data = {"bar name": {"2024-03-03": [{"trap 1"}, {"trap 2"}]}}
const Graphs = ({client, title, data, threshold, graphPeriod}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [chartID, setChartID] = useState((client+"-"+title).replace(/[^\x00-\x7F]+\ *(?:[^\x00-\x7F]| )*/g, "").replace(" ", ""));
  
    useEffect(() => {
        setLoading(true);

        //returns the number of days in a month calculated using short-circuit bitmask-modulo leapYear algorithm. is much quicker 
        //than running a '(new date("yyyy-mm-0")).getDate()' for every month in the graphs range. try to avoid doing that where
        //we can
        // https://stackoverflow.com/questions/315760/what-is-the-best-way-to-determine-the-number-of-days-in-a-month-with-javascript
        function daysInMonth(m, y){
            return m===2?y&3||!(y%25)&&y&15?28:29:30+(m+(m>>3)&1);
        }

        let monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        
        let traps = Object.keys(data);
        let chartData = {};

    //workout graph periods
        let periodNames = [];
        let initPeriodNames = [];

        let latestYM = "0";
        for(let i = 0; i < traps.length; i++)
        {
            if(data[traps[i]] !== undefined)
            {
                let subDates = Object.keys(data[traps[i]]);
                for(let j = 0; j < subDates.length; j++)
                {
                    let currDate = subDates[j].split("-");
                    if((currDate[0]+""+currDate[1]) > latestYM.replaceAll("-", ""))
                    {
                        latestYM = (currDate[0]+"-"+currDate[1]);
                    }
                }
            }
        }
        

        //6 Month, Monthly, Weekly, Bi-Weekly
        if(graphPeriod === "6 Month")
        {
            initPeriodNames = ["Jan - Jun", "Jul - Dec"]; //define the base name for x-axis periods(we want "Jan - Jun 2023", "Jul - Dec 2023", "Jan - Jun 2024", etc)
        }
        else if(graphPeriod === "Monthly")
        {
            //define the base name for x-axis periods(we want "Jan 23", "Jun 23", "Feb 23", etc)
            initPeriodNames = monthNames;
        }
        else if(graphPeriod === "Weekly")
        {
            //define the base name for x-axis periods(we want "1st Week Jan 23", etc)
            let weekNames = ["1st Week", "2nd Week", "3rd Week", "4th Week", "5th Week", "6th Week"];
            let weeks=[];
            let latestYMSplit = latestYM.split("-");
            let daysInLastMonth = daysInMonth(latestYMSplit[1]-1, latestYMSplit[0]);
            for(let i = 1; i <= daysInLastMonth; i++)
            {
                let currweek = Math.ceil(new Date(latestYMSplit[0]+"-"+(latestYMSplit[1]-1 < 10 ? "0" : '')+(latestYMSplit[1]-1)+"-"+(i < 10 ? "0" : '')+i).getDate() / 7);
                if(!weeks.includes(weekNames[currweek-1]))
                {
                    weeks.push(weekNames[currweek-1]);
                }
            }
            
            for(let i = 0; i < weeks.length; i++)
            {
                initPeriodNames.push(weeks[i]+" "+monthNames[latestYMSplit[1]-1]);
            }
        }
        else if(graphPeriod === "Bi-Weekly")
        {
            //still being worked on
            //define the base name for x-axis periods(we want "Jan 23", "Jun 23", "Feb 23", etc)
            let weekNames = ["1/2 Week", "1 Week", "1 1/2 Week", "2 Week", "2 1/2 Week", "3 Week", "3 1/2 Week", "4 Week"]

            for(let i = 0; i < monthNames.length; i++)
            {
                for(let j = 0; j < weekNames.length; j++)
                {
                    initPeriodNames.push(weekNames[j]+" "+monthNames[i]);
                }
            }
        }

        //Loop through traps to find years appearing in the data and then itterate on above initPeriodNames to define n periods per year
        //We calculate the periods first, so that for periods where there is no data we can define a value of 0, to keep the graph series data happy
        for(let i = 0; i < traps.length; i++)
        {
            if(data[traps[i]] !== undefined)
            {
                let subDates = Object.keys(data[traps[i]]);
                for(let j = 0; j < subDates.length; j++)
                {
                    for(let k = 0; k < initPeriodNames.length; k++)
                    {
                        let newPeriodName = initPeriodNames[k]+" "+subDates[j].split("-")[0];
                        if(!periodNames.includes(newPeriodName))
                        {
                            periodNames.push(newPeriodName);
                        }
                    }
                }
            }
        }

        //Loop through the traps and aggregate the data based on the periods above
        for(let i = 0; i < traps.length; i++)
        {
            //instantiate an array for the current trap type
            if(!chartData[traps[i]])
            {
                chartData[traps[i]] = [];
            }

            //Loop through each period calculated above
            for(let j = 0; j < periodNames.length; j++)
            {
                let currentPeriodTotal = 0; //define the default value for this period
                if(data[traps[i]] !== undefined)
                {
                    let trapDates = Object.keys(data[traps[i]]); //data = {"bar name/trap": {"2024-03-03": [{"trap 1"}, {"trap 2"}]}}
                    for(let k = 0; k < trapDates.length; k++)
                    {
                        let currentTrapDate = trapDates[k].split("-");
                        let newPeriodName = "";

                        //Figure out what period the currentTrapDate falls into
                        //6 Month, Monthly, Weekly, Bi-Weekly
                        if(graphPeriod === "6 Month")
                        {
                            newPeriodName = initPeriodNames[0]+" "+currentTrapDate[0];
                            if((currentTrapDate[1]-1) > 5)
                            {
                                newPeriodName = initPeriodNames[1]+" "+currentTrapDate[0];
                            }
                        }
                        else if(graphPeriod === "Monthly")
                        {
                            newPeriodName = initPeriodNames[currentTrapDate[1]-1]+" "+currentTrapDate[0];
                        }
                        else if(graphPeriod === "Weekly")
                        {
                            let weekNames = ["1st Week", "2nd Week", "3rd Week", "4th Week", "5th Week", "6th Week"];
                            let currweek = Math.ceil(new Date(currentTrapDate[0]+"-"+(currentTrapDate[1]-1 < 10 ? "0" : '')+(currentTrapDate[1]-1)+"-"+(currentTrapDate[2] < 10 ? "0" : '')+currentTrapDate[2]).getDate() / 7);
                            newPeriodName = weekNames[currweek-1]+" "+monthNames[currentTrapDate[1]-1]+" "+currentTrapDate[0];
                        }
                        else if(graphPeriod === "Bi-Weekly")
                        {

                        }

                        //If the currentTrapDate is in the current period we are aggregating, then get all traps on currentTrapDate and add them to the period total
                        if(periodNames[j] == newPeriodName)
                        {
                            let subTraps = data[traps[i]][trapDates[k]];
                            for(let l = 0; l < subTraps.length; l++)
                            {
                                let currentTrapKeys = Object.keys(subTraps[l]);
                                for(let m = 0; m < currentTrapKeys.length; m++)
                                {
                                    if(currentTrapKeys[m].toLowerCase().includes("activity")) //These traps hold 1 'pest', so flagging activity means we add 1
                                    {
                                        currentPeriodTotal++;
                                    }
                                    else if(currentTrapKeys[m].toLowerCase().includes("count")) //These traps hold more than 1 'pest', so we add the count reported
                                    {
                                        currentPeriodTotal = currentPeriodTotal + subTraps[l][currentTrapKeys[m]];
                                    }
                                }   
                            }
                        }
                    }
                }
                chartData[traps[i]].push(currentPeriodTotal); //Add the aggregated amount for this period to the traps graph series
            }
        }

        //workout threshold divisions based on the threshold passed to the graph
        let threshSection = threshold/3;
        let threshLow = Math.ceil(threshSection);
        let threshMed = Math.ceil(threshSection)+Math.ceil(threshSection);
        let threshHigh = threshold;

        let seriesData = traps.map((name, sid) => {
            return {
                name: name,
                type: 'bar',
                label: {
                    show: true,
                    formatter: function(param) {
                        return param.data == 0 ? '': name[0] + param.data; //if the data is 0, dont show the value. if there is a value, add the first character of the series to the name
                    }
                    //formatter: (params) => Math.round(params.value * 1000) / 10 + '%'
                },
                data: chartData[name],
                markLine: {
                    silent: true,
                    lineStyle: {
                        color: '#333'
                    },
                    data: [
                        {
                            yAxis: threshLow
                        },
                        {
                            yAxis: threshMed
                        },
                        {
                            yAxis: threshHigh
                        }
                    ]
                }
            };
        });


        //Calculate the average between series per period to show as a line. an alternative to this would be to calculate the actual regression line but thats too much work for now
        chartData["Trendline"] = [];
        for(let i = 0; i < periodNames.length; i++)
        {
            let tot = 0;
            for(let j = 0; j < traps.length; j++)
            {
                tot = tot+chartData[traps[j]][i];
            }
            if(traps.length > 0)
            {
                chartData["Trendline"].push(Math.round(tot/traps.length));
            }
            else
            {
                chartData["Trendline"].push(0);
            }
        }
        seriesData.push({
            name: 'Trend',
            type: 'line',
            data: chartData["Trendline"]
        });

        const chart = echarts.init(document.getElementById(chartID));

        let options = {
            title: {
                text: title+" | "+traps.join(", ")+" | "+ client,
            },
            tooltip: {},
            xAxis: {
                name: "Period",
                data: periodNames
            },
            yAxis: [
                {
                    name: "Total Count",
                }
            ],
            visualMap: {
                top: 50,
                right: 10,
                pieces: [
                    {
                        gt: 0,
                        lte: threshLow,
                        color: '#93CE07'
                    },
                    {
                        gt: threshLow,
                        lte: threshMed,
                        color: '#FBDB0F'
                    },
                    {
                        gt: threshMed,
                        lte: threshHigh,
                        color: '#FD0100'
                    },
                    {
                        gt: threshHigh,
                        color: '#AC3B2A'
                    }
                ],
                outOfRange: {
                    color: '#999'
                }
            },
            series: seriesData,
        };

        chart.setOption(options);
        
        setLoading(false);
    }, [data]);

    return (
        <div>
            {loading && <div>Loading...</div>}
            {error && <div>Error: {error}</div>}
            <div id={chartID} style={{ width: "100%", height: "400px" }} />
        </div>
    );
};

export default Graphs;
