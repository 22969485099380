import React, { useState, useEffect } from "react";
import { Container, Card, Spinner, Image } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { getTasks, getSubmissionsList, getSiteFormId } from "../../api";
import Bookings from "./Bookings";
import SiteForm from "../SiteForms/SiteForm";
import { ADMIN } from "../../Util/constants";
import TextField from "@mui/material/TextField";
import pestEliteLogo from "../../styles/PE_Logo-removebg-preview-e1659271343336.png";
import { Pagination } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";

const BookingSubmissions = () => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5); // State for items per page
  const isAdmin = localStorage.getItem("role") === ADMIN;
  const [existingSites, setSiteVisit] = useState([]);
  const [showSiteForm, setShowSiteForm] = useState(false);
  const [bookingRefId, setbookingRefId] = useState(null);
  const [isNew, setisNew] = useState(false);
  const pageLimitOptions = [5, 10, 20, 50, 100]; 

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        let submissionsData = await getTasks(setLoading);
        if (!isAdmin) {
          const userEmail = localStorage.getItem("email");
          submissionsData = submissionsData.filter((submission) =>
            submission.data.technician.includes(userEmail),
          );
        }
        const siteFormData = await getSubmissionsList(setLoading);
        //keep track of whats started bookingRef
        const submissionIds = siteFormData.map((submission) => submission.data.bookingRef);
        setSiteVisit(submissionIds);
        setSubmissions(submissionsData);
      } catch (error) {
        setError(`Error fetching submissions: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchSubmissions(); // eslint-disable-next-line
  }, []);

  const handleRowClick = (submissionId) => {
    setSelectedSubmissionId(submissionId);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // reset on search
  };

  const bookingStatus = (id) => {
    if(existingSites.indexOf(id) === -1){
      return "Not Started";
    }
    return "Site Visited";
  }

  const filteredSubmissions = submissions.filter((submission) => {
    const clientName = submission.data?.client?.data?.clientName.toLowerCase();
    const technicianNames = [];

    //nested tech since multiple
    if (!submission.metadata.selectData.technician.data) {
      Object.values(submission.metadata.selectData.technician).forEach(
        (tech) => {
          technicianNames.push(
            `${tech.data.name.toLowerCase()} ${tech.data.surname.toLowerCase()}`
          );
        }
      );
    } else {
      technicianNames.push(
        `${submission.metadata.selectData.technician.data.name.toLowerCase()} ${submission.metadata.selectData.technician.data.surname.toLowerCase()}`
      );
    }

    const searchLowerCase = searchQuery.toLowerCase();
    return (
      clientName.includes(searchLowerCase) ||
      technicianNames.some((name) => name.includes(searchLowerCase))
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredSubmissions.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (value) => {
    setCurrentPage(value);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  const handleBackToList = () => {
    setSelectedSubmissionId(null);
  };

  const handleRowTechClick = async (id) => {
     //check of id exits
      if (existingSites.indexOf(id) !== -1){ //exists
        //get id for bookingref
       const siteForm = await getSiteFormId(setLoading, id);
       const siteFormId = siteForm.data._id;
       setbookingRefId(siteFormId);
        console.log("found","found");
      }else{
        setisNew(true);
      }
      //setbookingRefId(id);
      
     setShowSiteForm(true);
  }

  return (
    <Container fluid>
      <div className="formio-brand container-fluid">
        <h3>BOOKINGS</h3>
        <Image
          src={pestEliteLogo}
          alt="Pest Elite Logo"
          fluid
          className="m-2"
          style={{ width: "70px", height: "auto" }}
        />
      </div>
      <Card>
        <Card.Body>
          {selectedSubmissionId ? (
            <Card.Title style={{ textAlign: "center" }}>
              EDIT TASK BOOKING
            </Card.Title>
          ) : (
            <Card.Title style={{ textAlign: "center" }}>
              TASK BOOKING LIST
            </Card.Title>
          )}
          {loading && (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          )}
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          {!loading && !error && (
            <>
              {selectedSubmissionId ? (
                <>
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    onClick={handleBackToList}
                    style={{ cursor: "pointer" }}
                  />
                  {selectedSubmissionId === true ? (
                    <Bookings />
                  ) : (
                    <Bookings submissionId={selectedSubmissionId} />
                  )}
                </>
              ) : (
                <>
                  <div className="col-md-6 mb-3" style={{width:"100%"}}>
                    <TextField
                      id="outlined-basic"
                      label="Search"
                      size="small"
                      variant="outlined"
                      value={searchQuery}
                      onChange={handleSearch}
                    />
                    {isAdmin &&
                      <Fab
                        style={{float:"right"}}
                        size="medium"
                        color="primary"
                        aria-label="add"
                        variant="extended"
                        onClick={() => handleRowClick(true)}
                      >
                        <AddIcon sx={{ mr: 1 }}/>
                        Add
                      </Fab>
                    }
                  </div>
                  {filteredSubmissions.length === 0 ? (
                    <div className="alert alert-info" role="alert">
                      No assigned tasks.
                    </div>
                  ) : (
                    <>
                      <Table className="table-bordered table-striped">
                        <Thead>
                          <Tr>
                            <Th>No.</Th>
                            <Th>Client Name</Th>
                            <Th>Telephone</Th>
                            <Th>Technician</Th>
                            <Th>Due Date</Th>
                            <Th>Status</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {currentItems.map((submission, index) => (
                            <Tr
                              key={index}
                              className={index % 2 === 0 ? "even" : "odd"}
                              onClick={
                                isAdmin
                                  ? () => handleRowClick(submission._id)
                                  : () => handleRowTechClick(submission._id)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <Td style={{width: "40px"}}>{indexOfFirstItem + index + 1}</Td>
                              <Td>
                                {submission.data?.client?.data?.clientName}
                              </Td>
                              <Td>
                                {submission.data?.client?.data?.telephone}
                              </Td>
                              {!submission.metadata.selectData.technician
                                .data ? (
                                <Td>
                                  {
                                    Object.entries(
                                      submission.metadata.selectData.technician,
                                    )
                                      .map(
                                        ([key, value], i) =>
                                          `${value.data.name} ${value.data.surname}`,
                                      )
                                      .join(" ,") // show names comma separated
                                  }
                                </Td>
                              ) : (
                                <Td>
                                  {
                                    submission.metadata.selectData.technician
                                      .data.name
                                  }{" "}
                                  {
                                    submission.metadata.selectData.technician
                                      .data.surname
                                  }
                                </Td>
                              )}
                              <Td>
                                {new Date(
                                  submission.data.dueDate,
                                ).toLocaleDateString()}
                              </Td>
                              <Td>{bookingStatus(submission._id)}</Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                      <div className="d-flex justify-content-center mt-3">
                        <Pagination
                          count={Math.ceil(
                            filteredSubmissions.length / itemsPerPage,
                          )}
                          page={currentPage}
                          onChange={handlePageChange}
                          color="primary"
                        />
                        <div className="ml-2 d-flex align-items-center">
                          <InputLabel style={{ marginRight: "8px" }}>
                            Page Items
                          </InputLabel>
                          <FormControl
                            sx={{ minWidth: 60 }}
                            size="small"
                            className="ml-2"
                          >
                            <Select
                              value={itemsPerPage}
                              onChange={handleItemsPerPageChange}
                              displayEmpty
                            >
                              {pageLimitOptions.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Card.Body>
      </Card>
      {showSiteForm && isNew && <SiteForm bookingRef={bookingRefId} />}
      {showSiteForm && !isNew && <SiteForm submissionId={bookingRefId} />}
    </Container>
  );
};

export default BookingSubmissions;
