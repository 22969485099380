import React, { useState, useEffect } from "react";
import { Container, Card, Image } from "react-bootstrap";
import { getUsers } from "../../api";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  Pagination,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import TextField from "@mui/material/TextField";
import Users from "./Users";
import { ADMIN } from "../../Util/constants";
import pestEliteLogo from "../../styles/PE_Logo-removebg-preview-e1659271343336.png";

const UsersSubmission = () => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const pageLimitOptions = [5, 10, 20, 50, 100];

  const isAdmin = localStorage.getItem("role") === ADMIN;

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const submissionsData = await getUsers(setLoading);
        setSubmissions(submissionsData);
      } catch (error) {
        setError(`Error fetching submissions: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchSubmissions();
  }, []);

  const handleRowClick = (submissionId) => {
    setSelectedSubmissionId(submissionId);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // reset on search
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredSubmissions = submissions.filter(
    (submission) =>
      submission.data.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      submission.data.role.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const currentItems = filteredSubmissions.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handlePageLimitChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const handleBackToList = () => {
    setSelectedSubmissionId(null);
  };

  return (
    <Container fluid className="mb-2">
      <div className="formio-brand container-fluid">
        <h3>USERS</h3>
        <Image
          src={pestEliteLogo}
          alt="Pest Elite Logo"
          fluid
          className="m-2"
          style={{ width: "70px", height: "auto" }}
        />
      </div>
      <Card>
        <Card.Body>
          {selectedSubmissionId ? (
            <Card.Title style={{ textAlign: "center" }}>EDIT USER</Card.Title>
          ) : (
            <Card.Title style={{ textAlign: "center" }}>USERS LIST</Card.Title>
          )}
          {loading && <p>Loading...</p>}
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          {!loading && !error && (
            <>
              {selectedSubmissionId ? (
                <>
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    onClick={handleBackToList}
                    style={{ cursor: "pointer" }}
                  />
                  {selectedSubmissionId === true ? (
                    <Users />
                  ) : (
                    <Users submissionId={selectedSubmissionId} />
                  )}
                </>
              ) : (
                <div>
                  <div className="col-md-6 mb-3" style={{width:"100%"}}>
                    <TextField
                      id="outlined-basic"
                      label="Search"
                      size="small"
                      variant="outlined"
                      placeholder="Search by Email or Role"
                      value={searchQuery}
                      onChange={handleSearch}
                    />
                    {isAdmin &&
                      <Fab
                        style={{float:"right"}}
                        size="medium"
                        color="primary"
                        aria-label="add"
                        variant="extended"
                        onClick={() => handleRowClick(true)}
                      >
                        <AddIcon sx={{ mr: 1 }}/>
                        Add
                      </Fab>
                    }
                  </div>

                  <Table className="table-bordered table-striped">
                    <Thead>
                      <Tr>
                        <Th style={{width: "40px"}}>No.</Th>
                        <Th style={{ width: "50%" }}>Email</Th>
                        <Th>Role</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {currentItems.map((submission, index) => (
                        <Tr
                          key={index}
                          onClick={isAdmin
                            ? () => handleRowClick(submission._id)
                            : null
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <Td>{indexOfFirstItem + index + 1}</Td>
                          <Td>{submission.data.email}</Td>
                          <Td>{submission.data.role.toUpperCase()}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>

                  <div className="d-flex justify-content-center mt-3 mb-3">
                    <Pagination
                      count={Math.ceil(
                        filteredSubmissions.length / itemsPerPage,
                      )}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                    />
                    <div className="ml-2 d-flex align-items-center">
                      <InputLabel style={{ marginRight: "8px" }}>
                        Page Items
                      </InputLabel>
                      <FormControl
                        sx={{ minWidth: 60 }}
                        size="small"
                        className="ml-2"
                      >
                        <Select
                          value={itemsPerPage}
                          onChange={handlePageLimitChange}
                          displayEmpty
                        >
                          {pageLimitOptions.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default UsersSubmission;
