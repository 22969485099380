import { Form } from "@formio/react";
import { API_BASE_URL } from "../../Util/constants";

const Pesticide = ({ submissionId}) => {
  const formName = "pesticidesregister";

  const onSubmitHandler = () => {
    console.log("Form submitted");
  };

  const onChangeHandler = (event) => {
    console.log("Form value changed:", event);
  };

  return (
    <>
      <div className="pt-3">
        <div className="card p-2">
          <div className="card-body bg-light rounded-3">
            {submissionId ? (
              <Form
                src={`${API_BASE_URL}/${formName}/submission/${submissionId}`}
                onSubmit={() => onSubmitHandler()}
                onChange={onChangeHandler}
              />
            ) : (
              // Render the default form for new mode
              <Form
                src={`${API_BASE_URL}/${formName}`}
                onSubmit={(submission) => onSubmitHandler(submission)}
                onChange={onChangeHandler}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Pesticide;
