import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Nav,
  Row,
  Tab,
  Card,
  NavDropdown,
  Image,
  Collapse,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Footer from "./Footer";
import SiteForm from "./SiteForms/SiteForm";
import "../App.css";
import Client from "./Clients/Clients";
import Dashboard from "./Dashboard";
import Pesticide from "./Pesticides/Pesticides";
import SubmissionsList from "./SiteForms/SiteFormSubmissions";
import UsersSubmission from "./Users/UsersSubmissions";
import ClientSubmissions from "./Clients/ClientsSubmission";
import PesticidesSubmission from "./Pesticides/PesticidesSubmission";
import { ADMIN } from "../Util/constants";
import Users from "./Users/Users";
import Bookings from "./Bookings/Bookings";
import BookingSubmissions from "./Bookings/BookingsSubmissions";
import UsageLog from "./UsageLog/UsageLogSubmissions";
import { useNavigate, Link } from "react-router-dom";
import { faUserShield, faUserCog } from "@fortawesome/free-solid-svg-icons";
import pestEliteLogo from "../styles/PE_Logo-removebg-preview-e1659271343336.png";

import {
  Dashboard as DashboardIcon,
  Group as UsersIcon,
  AddCircle as AddCircleIcon,
  ListAlt as ListAltIcon,
  Assignment as AssignmentIcon,
  PeopleAlt as PeopleAltIcon,
  Description as DescriptionIcon,
  BugReport as BugReportIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  AssignmentLate as AssignmentLateIcon,
} from "@mui/icons-material";

const Components = () => {
  const [activeTab, setActiveTab] = useState("dashboard");
  const [key, setKey] = useState(0);
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);

  const handleTabChanges = (tab) => {
    setActiveTab(tab);
    setKey((prevKey) => prevKey + 1);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    handleTabChanges("dashboard");
  }, []);

  const isAdmin = localStorage.getItem("role") === ADMIN;

  return (
    <Container fluid className="mt-3 mb-5">
      <Tab.Container id="left-tabs-example" defaultActiveKey="dashboard">
        <Row>
          <Col xs={12} md={3} lg={2}>
            <Card className="mb-3">
              <Card.Header>
                <Container
                  fluid
                  className="d-flex justify-content-between d-lg-none"
                >
                  <Link to={"/"} className="text-decoration-none">
                    <div className="formio-brand">
                      <Image
                        src={pestEliteLogo}
                        alt="Pest Elite Logo"
                        fluid
                        className="me-2"
                        style={{ width: "60px", height: "auto" }}
                      />
                    </div>
                  </Link>

                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={() => setCollapsed(!collapsed)}
                    aria-controls="responsive-navbar-nav"
                    aria-expanded={!collapsed ? "true" : "false"}
                    aria-label="Toggle navigation"
                  >
                    <FontAwesomeIcon icon={faBars} />
                  </button>
                </Container>
                <Container>
                  <Collapse in={!collapsed}>
                    <Nav
                      variant="pills"
                      className="flex-column custom-nav mt-2 mb-4"
                      activeKey={activeTab}
                      onSelect={(selectedKey) => handleTabChanges(selectedKey)}
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="dashboard">
                          <DashboardIcon /> Dashboard
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="bookingsList">
                          <AssignmentIcon /> Task Bookings
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="clientList">
                          <PeopleAltIcon /> Clients
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="pesticidesList">
                          <BugReportIcon /> Pesticides
                        </Nav.Link>
                      </Nav.Item>
                      {isAdmin && (
                        <Nav.Item>
                          <Nav.Link eventKey="users">
                            <UsersIcon /> Users
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      <Nav.Item>
                        <Nav.Link
                          eventKey="usagelog"
                          onClick={() => handleTabChanges("usagelog")}
                        >
                          <DescriptionIcon />{" "}
                          <span className="nav-link-label">Usage Log</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Link
                        onClick={handleLogout}
                        className="nav-link d-lg-none"
                      >
                        {isAdmin ? (
                          <>
                            <FontAwesomeIcon
                              icon={faUserShield}
                              className="me-1"
                            />
                            <b>LOGOUT (Admin)</b>
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon
                              icon={faUserCog}
                              className="me-1"
                            />
                            <b>LOGOUT(Technician)</b>
                          </>
                        )}
                      </Nav.Link>
                    </Nav>
                  </Collapse>
                </Container>
              </Card.Header>
            </Card>
          </Col>
          <Col xs={12} md={9} lg={10}>
            <Tab.Content>
              <Tab.Pane eventKey="clientList" key={key + "clientList"}>
                <ClientSubmissions />
              </Tab.Pane>
              <Tab.Pane eventKey="dashboard" key={key + "dashboard"}>
                <Dashboard />
              </Tab.Pane>
              <Tab.Pane eventKey="pesticidesList" key={key + "pesticidesList"}>
                <PesticidesSubmission />
              </Tab.Pane>
              <Tab.Pane eventKey="users" key={key + "users"}>
                <UsersSubmission />
              </Tab.Pane>
              <Tab.Pane eventKey="bookingsList" key={key + "bookingsList"}>
                <BookingSubmissions />
              </Tab.Pane>
              <Tab.Pane eventKey="usagelog" key={key + "usagelog"}>
                <UsageLog />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
        <Row>
          <Footer />
        </Row>
      </Tab.Container>
    </Container>
  );
};

export default Components;
